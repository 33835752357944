import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const precios = () => (
  <Layout>
    <Seo title="Página en proceso" />
    <h1>Argos</h1>
    <h3>Página de precios</h3>
    <p>Inscribete ahora nuestros primeros 50 usuarios tendran acceso gratis a nuestra plataforma.</p>
  </Layout>
)

export default precios
